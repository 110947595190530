








































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import {
  ActivityDto, AuditFlowScope,
  CmsAnchorDto,
  CmsAnchorDtoPagedResultDto, DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
  QuestionType, SelectListItem, TrainDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";


@Component({
  name: "AnchorList",
  components: {
    PagedTableView,
    ExportButton,
    AgileAuditTag
  },
})
export default class AnchorList extends Vue {
  queryForm = {
    surName: "",
    trainTitle:"",
    trainTypeId:undefined,
    startDate:undefined,
    isMakeUpExamination:undefined,
    auditStatus:undefined,
    endDate:undefined
  };
  exportFieldList = [
    "志愿者编号",
    "姓名",
    "培训主题",
    "培训地点",
    "培训类型",
    "开始时间",
    "结束时间",
    "反馈时间",
    "培训次数",
    "审核状态"
  ];

  typeList:DataDictionaryDto[]=[];
  statusTypeList: SelectListItem[] = [];
  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "AuditStatus",
      })
      .then((res) => {
        this.statusTypeList = res;
      });
  }
  trains:TrainDto[] = [];
  async fetchtrainList() {
    await api.train
      .getAll({
        maxResultCount: 65538,
      })
      .then((res) => {
        this.trains = res.items!;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.trainRecord.getAll(params);
  }

  created(){
    this.fetchDataDictionary();
    this.fetchEnumType();
    this.fetchtrainList();
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "TrainType", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.typeList = res.items!;
      });
  }

  // 编辑
  handleEdit(index: number, row: any) {
    this.id = row.id!;
    this.$router.push({
      name: "trainRecordEdit",
      params: { id: row.id! + "" },
    });
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.TrainRecord,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  // 详情
  handleDetail(index: number, row: any) {
    this.id = row.id!;
    this.$router.push({
      name: "trainRecordDetail",
      params: { id: row.id! + "" },
    });
  }
}
